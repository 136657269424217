
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";


export default {
  page: {
    title: "Restaurant Menu Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      
      tableData: [],

      title: "Categories ",
      items: [
        {
          text: "View",
        },
        {
          text: "Categories",
          active: true,
        },
      ],
      filterArr :[
        {'filterID':0,'filterName':'All'},
        {'filterID':2,'filterName':'Available'},
        {'filterID':3,'filterName':'Out Of Stock'},
        ],
     
      showDismissibleAlert: false,
      filterID:"",
      categoryList:[],
      restaurantID:"",
      restBranchID: this.$storageData.profile.restBranchID,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100,500,1000,2000,"All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
     
        {
          key: "title",
          
          sortable: true,
          //image: true,
        },
        {
          key: "price",
          sortable: true,
        },
        {
          key: "foodType",
          
          sortable: true,
          //image: true,
        },
        {
          key: "mealName",
          
          sortable: true,
          //image: true,
        },
        
        {
          key: "stockAvailability",
          
          sortable: true,
          //image: true,
        },
        {
          key: "ChefsChoice",
          
          sortable: true,
          //image: true,
        },
        {
          key: "created",
          
          sortable: true,
          //image: true,
        },

        
        
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getRestaurantMenuList(0); // 1-> default all menu displayed
    this.getCuisineCategory();
    


  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getRestaurantMenuList(filterID){
         this.axios
        .post(this.$loggedRole+"/restaurantMenuList", {'restBranchID':this.$storageData.profile.restBranchID,'restID':this.$storageData.profile.restID,'filterID':filterID}//this.restBranchID}
        )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           //console.log((response));
          
        });
    },
    getCuisineCategory(){
        this.axios
        .post(this.$loggedRole+"/getRestaurantCategoryList", {'restID':this.$storageData.profile.restID} )
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.categoryList = response.data.data;
           //console.log((response));
          
        });
    },
    updateStock(menuID){
        this.axios
        .post(this.$loggedRole+"/updateMenuStock", {'menuID':menuID}
           )
        .then(() => {
         this.showDismissibleAlert = 1;
          setTimeout(() => {
                   this.showDismissibleAlert = 0;
              },2000);
          
        });
    },
    updateAsChefSpecial(menuID){
         this.axios
        .post(this.$loggedRole+"/updateAsChefSpecial", {'menuID':menuID}
           )
        .then(() => {
         this.showDismissibleAlert = 1;
          setTimeout(() => {
                   this.showDismissibleAlert = 0;
              },2000);
          
        });
    },
    addFilter(){
      let filter = this.filterID.filterID;
     // alert(filter);
      if(filter!="undefined"){
        this.getRestaurantMenuList(this.filterID.filterID);
      }
      
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.rowType === 'cuisine_category') return 'table-color'
    },
    gotoCategory(){
        //if(this.$route.params.questionId){
        this.scrollIntoView();
           // this.scrollOnLoad = false;
       // }
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
     <b-alert v-model="showDismissibleAlert" style="float:right;" variant="success" dismissible>
      Cuisnie Update succcessfully !
    </b-alert>
     
    <PageHeader :title="title" :items="items" />
     
   
    <div class="row col-md-12">
      
     <div class="col-md-3">
        <div class="card">
          <div class="card-body">
              <ul  v-for="(data) in categoryList"
                        :key="data.id">
                  <li @click="gotoCategory();" style="cursor:pointer;">{{data.categoryName}} <i class="uil uil-angle-right"></i></li>
              </ul>
          </div>
        </div>
     </div>

      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
                <div class="row mt-4">
             
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <div class="col-md-3"> 
                 <multiselect v-model="filterID" :options="filterArr" track-by="filterID" label="filterName" :show-labels="false" @input="addFilter();"
                      ></multiselect>
              </div>
              
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                hover
               
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                sticky-header="500px"
                 fixed-header
                :tbody-tr-class="rowClass"
              >
              
               <template  v-slot:cell(stockAvailability)="data" >
                   <div v-if="data.item.rowType=='cuisine'"
                              class="form-check form-switch form-switch-md"
                              dir="ltr"
                            >
                  
                  <input v-if="data.item.outOfStock==1" class="form-check-input" type="checkbox" :checked="data.item.outOfStock" id="autoSizingCheck2" title="Out of Stock" @change="updateStock(data.item.menuID)">
                  <input v-else class="form-check-input" type="checkbox" :checked="data.item.outOfStock" id="autoSizingCheck2" title="Available" @change="updateStock(data.item.menuID)">
                  
                  </div>
                </template>
                <template  v-slot:cell(ChefsChoice)="data" >
                   <div v-if="data.item.rowType=='cuisine'"
                              class="form-check form-switch form-switch-md"
                              dir="ltr"
                            >
                  
                  <input v-if="data.item.chefSpecial==1" class="form-check-input" type="checkbox" :checked="data.item.chefSpecial" id="autoSizingCheck2" title="Chef's Special" @change="updateAsChefSpecial(data.item.menuID)">
                  <input v-else class="form-check-input" type="checkbox" :checked="data.item.chefSpecial" id="autoSizingCheck2" title="Normal Dish" @change="updateAsChefSpecial(data.item.menuID)">
                  
                  </div>
                </template>
                

                <template  v-slot:cell(title)="data" >
                    <div v-if="data.item.rowType=='cuisine_category'" v-bind:colspan="6">
                      <label>{{data.item.categoryName}}</label>
                    </div>
                    <div v-else>
                      {{data.item.title}}
                    </div>
                </template>
  
              </b-table>
                
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                  
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.form-check-input:checked{
  background-color:#A4827D;
    border-color: #A4827D;
}
.table-color{
  --bs-table-accent-bg: #ececec!important;
    color: black !important;
}
</style>
